import React from 'react';
import Layout from '../components/Layout';

import Sidebar from '../components/Sidebar';
import Classes from './index.module.css';

import arrow_right from '../assets/images/arrow_right.png';
import arrow_left from '../assets/images/arrow_left.png';
import { Link } from 'gatsby';

const Current = () => (
  <Layout>
    <Sidebar showTabs="madarij" />
    <div div className="w-100">
      <h5 className="mb-5">
        <span className={Classes.blink}>[NEW]</span>
        <span className={Classes.blink_infinite}>
          <img
            className="img-fluid img-profile mx-auto mb-2,"
            src={arrow_right}
            alt=""
            height="20"
            width="20"
          />
        </span>
        <span>
          <Link to="/army/">
            Army <span className={Classes.blink}>/Good Company </span>{' '}
            <span className={Classes.blink}>--[6/3/22]</span>
          </Link>
        </span>
      </h5>
    </div>
    <div>
      <a href="https://youtu.be/80ZAUGZGlW0" target="blank">
        <h3>After Ramadan plan</h3>
      </a>
      <ul>
        <li>
          <a href="https://youtu.be/80ZAUGZGlW0" target="blank">
            <p> [Click Here For Recording]</p>
          </a>
        </li>
        <li>
          <a href="https://myislam.net/static/post-ramadan.pdf" target="blank">
            Slides in PDF format
          </a>
        </li>
      </ul>
      <ol>
        <p />
        <li>
          <Link to="/satan-blocker/">
            <h5>
              Shield: Satan Blocker <span></span>
            </h5>
          </Link>
          <ul>
            <li>
              <Link to="/satan-blocker/">Satan Blocker (Click Here)</Link>
            </li>
          </ul>
        </li>
        <p />
        <p />
        <li>
          <Link to="/duaa/">
            <h5>Weapon: Suplication</h5>
          </Link>
        </li>

        <ul>
          <li>
            <Link to="/duaa/">Click Here for More Details</Link>
          </li>
        </ul>

        <li>
          <Link to="/army/">
            <h5>
              Army: Good Company
              <span className={Classes.blink_infinite}>
                <img
                  className="img-fluid img-profile mx-auto mb-2,"
                  src={arrow_left}
                  alt=""
                  height="30"
                  width="30"
                />
              </span>
              <span className={Classes.blink}>We are here</span>{' '}
              <span className={Classes.blink}>-6/3/22</span>
            </h5>
          </Link>
          <ul>
            <li>
              <Link to="/army/">
                <span className={Classes.blink}>Army: </span> Click Here for
                More Details
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <a href="https://youtu.be/80ZAUGZGlW0">
            <h5>Plan: [This Series]</h5>
          </a>
          <ol>
            <li>
              <h6>Understand Steps of Satan</h6>
              <h6> خطوات الشيطان</h6>
            </li>
            <ul>
              <li>
                <Link to="/satan-steps/">Satan Steps (Click Here)</Link>
              </li>
            </ul>
          </ol>
        </li>
      </ol>
    </div>

    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="happy"
      >
        <div className="w-100">
          <h3 className="mb-5">
            <h3 style={{ textAlign: 'center' }}>
              Practical Steps to Happiness From Madarij Assalekin (Ibn ElQayeem)
            </h3>
            <h3 style={{ textAlign: 'center' }}>مدارج السالكين</h3>{' '}
          </h3>
          <p style={{ fontSize: '15px', color: 'blue' }}>
            {' '}
            This is a practical workshop to reach happiness in this life and the
            hereafter. We will go through the steps of the travelers by Ibn
            ElQayeem مدارج السالكين (Refer to the{' '}
            <a href="#references">References </a> section) {'  '}
            <a
              key="https://www.facebook.com/groups/701352733924789"
              href="https://www.facebook.com/groups/701352733924789"
              target="_blank"
            >
              <i className={`fab ${'fa-facebook-f'}`} />
              acebook group
            </a>
            {'  '}
            <a
              href="https://www.youtube.com/playlist?list=PLNT8BTFvQYHLWc7EkqE0OYo1sRCuQwYzv"
              target="_blank"
            >
              <i className={`fab ${'fa-youtube'}`} />
              YouTube PlayList
            </a>
          </p>

          <ul className={Classes.FocusLinked}>
            <li>
              <a
                href="https://www.youtube.com/playlist?list=PLNT8BTFvQYHLWc7EkqE0OYo1sRCuQwYzv"
                target="blank"
              >
                Practical Steps to happiness - تطبيق كتاب مدارج السالكين
                (YouTube PlayList)
              </a>
            </li>
            <p />
            <p />
            <text></text>
            <br />

            <ul>
              <p />
              <p />
              <li>
                <Link to="/ramadan/">
                  Ramadan <span className={Classes.blink}>[5/7/2022]</span>
                </Link>
              </li>
              <p />
              <li>
                <>
                  <Link to="#gratitude">
                    The State of Gratitude - منزلة الشكر{' '}
                    <span className={Classes.blink}>[11/27/2021]</span>
                  </Link>
                </>
              </li>
              <li>
                <Link to="#grief">
                  The State of Grief - منزلة الحزن{' '}
                  <span className={Classes.blink}>[12/13/2020]</span>
                </Link>
              </li>

              <li>
                <Link to="/love/">
                  The State of Love - منزلة المحبة{' '}
                  <span className={Classes.blink}>[11/13/2020]</span>
                </Link>
              </li>

              <li>
                <>
                  <Link to="#patience">
                    The State of Patience - منزلة الصبر{' '}
                    <span className={Classes.blink}>[10/9/2020]</span>
                  </Link>
                </>
              </li>
              <li>
                <>
                  <Link to="#trust">
                    The State of Trust - منزلة الثقة{' '}
                    <span className={Classes.blink}>[10/2/2020]</span>
                  </Link>
                </>
              </li>

              <li>
                <>
                  <Link to="#stage1">
                    Stages 1-4: Consciousness - commitment - اليقظة - العزم{' '}
                    <span>[9/18/2020]</span>
                  </Link>
                </>
              </li>

              <li>
                <Link to="#step4">
                  Perfection - Ehsan -منزلة الاحسان{' '}
                  <span className={Classes.blink}>[8/25/2020]</span>
                </Link>
              </li>
              <li>
                <>
                  <a href="#Sincerity">
                    The state of Sincerity --منزلة الاخلاص <span></span>
                  </a>
                </>
              </li>
              <li>
                <Link to="#heart">
                  Protect Your Heart <span className={Classes.blink}></span>
                </Link>
              </li>
              <li>
                <Link to="#help">
                  Ask Allah For Help <span>[7/11/2020]</span>
                </Link>
              </li>
              <li>
                <>
                  <a href="#introduction">Introduction - 7/3/2020</a>
                </>
              </li>
            </ul>
          </ul>
          <h4>
            <a href="#references">References </a>
          </h4>
        </div>
      </section>

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="introduction"
      >
        <h4>Introduction</h4>
        <ul>
          <li>
            <a href="https://youtu.be/5ffrMcPFgTQ" target="blank">
              YouTube Video (Please share)
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/1_Practical_Steps_to_Happiness_Introduction_Final.pdf"
              target="blank"
            >
              Slides in PDF format to downlad or read on browser
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/1_Practical_Steps_to_Happiness_Introduction.pptx"
              target="blank"
            >
              Slides in PowerPoint format with voice to downlad
            </a>
          </li>
          <li>
            <a href="https://myislam.net/static/happiness_1.mp4" target="blank">
              Video in .mp4 format to downlad or watch on browser
            </a>
          </li>
          <li>
            <a
              href="#happy"
              style={{ color: 'red', fontSize: '30px', fontWeight: 'bolder' }}
            >
              Back to Top
            </a>
          </li>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="help"
      >
        <h4>1. First Step: Ask Allah For Help (Duaa and Monajah) : </h4>{' '}
        <ul></ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="love"
      >
        <h4>
          <Link to="/love/">
            The State of Love - منزلة المحبة{' '}
            <span className={Classes.blink}>[10/23/2020]</span>
          </Link>

          <span className={Classes.blink_infinite}>
            <img
              className="img-fluid img-profile mx-auto mb-2,"
              src={arrow_left}
              alt=""
              height="30"
              width="30"
            />
          </span>
          <span>We are here</span>
        </h4>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="stage1"
      >
        <h4>
          1. Steps 1-4: Consciousness - commitment - اليقظة - الفكرة البصيرة -
          العزم{' '}
        </h4>
        <ul>
          <li>
            <a href="https://youtu.be/l7dGtFTfy0Y" target="blank">
              YouTube Video (Please share)
            </a>
          </li>
          <li>
            <a href="https://myislam.net/static/1_Steps_1-4.pdf" target="blank">
              Slides in PDF format to downlad or read on browser
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/1_Madarej_Setps1-4.pptx"
              target="blank"
            >
              Slides in PowerPoint format with voice to downlad
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/1_Madarej_Setps1-4.mp4"
              target="blank"
            >
              Video in .mp4 format to downlad or watch on browser
            </a>
          </li>
          <li>
            <a
              href="#happy"
              style={{ color: 'red', fontSize: '30px', fontWeight: 'bolder' }}
            >
              Back to Top
            </a>
          </li>
          <p></p>
          <li>
            <h5>References for Consciousness:</h5>
          </li>
          <ul>
            <li>
              <a
                href="https://medium.com/@madarejassalekeen/practicing-the-way-of-the-travelers-madarij-assalikeen-563c260a0b27"
                target="blank"
              >
                [English] Practicing Madarej Assalekeen - تطبيق كتاب مدارج
                السالكين
              </a>
            </li>
          </ul>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="step5"
      >
        <h4>2. Step 5: Self-Evaluation - المحاسبة </h4>
        <ul>
          <li>
            <a
              href="https://MyIslam.net/static/Self_Evaluation.pdf"
              target="blank"
            >
              Written PDF to read
            </a>
          </li>
          <li>
            <a href="https://youtu.be/LjoQOXT3hY0" target="blank">
              YouTube Video (Please share) - Sept2020
            </a>
          </li>
          <li>
            <a href="https://youtu.be/myGZ0EgvCfU" target="blank">
              Preparation YouTube Video - 2014 (Please share)
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/2020Sept25Plan.pdf"
              target="blank"
            >
              Plan before the session in PDF format to downlad or read on
              browser
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/self_evaluation_old.pptx"
              target="blank"
            >
              Slides in PowerPoint format with voice to downlad
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/self_evaluation_old.mp4"
              target="blank"
            >
              Video in .mp4 format to downlad or watch on browser
            </a>
          </li>
          <li>
            <a
              href="#happy"
              style={{ color: 'red', fontSize: '30px', fontWeight: 'bolder' }}
            >
              Back to Top
            </a>
          </li>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="step6"
      >
        <h4>3. Step 6: Repentance - التوبة </h4>
        <ul>
          <li>
            <a
              href="https://MyIslam.net/static/3_Step6_Repemtance.pdf"
              target="blank"
            >
              Written PDF to read
            </a>
          </li>
          <li>
            <a href="https://youtu.be/TP-X1ME9Ivs" target="blank">
              Practicing Repentance Step1 YouTube Video (Please share) - 2014
            </a>
          </li>
          <li>
            <a href="https://youtu.be/XhrvEYD8BZY" target="blank">
              Practicing Repentance 2 - People's Right - 2014 (Please share)
            </a>
          </li>
          <li>
            <a
              href="https://MyIslam.net/static/2020Oct2Plan.pdf"
              target="blank"
            >
              Plan before the session in PDF format to downlad or read on
              browser
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/self_evaluation_old.pptx"
              target="blank"
            >
              Slides in PowerPoint format with voice to downlad
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/self_evaluation_old.mp4"
              target="blank"
            >
              Video in .mp4 format to downlad or watch on browser
            </a>
          </li>
          <li>
            <a
              href="#happy"
              style={{ color: 'red', fontSize: '30px', fontWeight: 'bolder' }}
            >
              Back to Top
            </a>
          </li>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="step2"
      >
        <h4>2. Second Step: Benefit from Opportunities : </h4>{' '}
        <ul>
          <li>
            <a href="https://youtu.be/w2Hh4G-Oi5s" target="blank">
              YouTube Video (Please share)
            </a>
          </li>
          <li>
            <a href="https://myislam.net/static/step2.pdf" target="blank">
              Slides in PDF format to downlad or read on browser
            </a>
          </li>
          <li>
            <a href="https://myislam.net/static/step2.pptx" target="blank">
              Slides in PowerPoint format with voice to downlad
            </a>
          </li>
          <li>
            <a href="https://myislam.net/static/step2.mp4" target="blank">
              Video in .mp4 format to downlad or watch on browser
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/2020Jul24_plan.pdf"
              target="blank"
            >
              Suggested and example action plan for the week
            </a>
          </li>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="Sincerity"
      >
        <h4>Sincerity - الاخلاص: </h4>{' '}
        <ul>
          <li>
            <a href="https://youtu.be/YT1EXbGnJy8" target="blank">
              YouTube Video (Please share)
            </a>
          </li>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="step4"
      >
        <h4>4. Step 4: Perfection - Ehsan - الاحسان : </h4>{' '}
        <ul>
          <li>
            <a href="https://youtu.be/txyYXI-xfPE" target="blank">
              YouTube Video (Please share)
            </a>
          </li>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="worry"
      >
        <ul className={Classes.FocusLinked}>
          <li>
            <h4>Medications for worries, stress, panic, and anxiety</h4>
          </li>
          <ol className={Classes.FocusLinked}>
            <li>
              <h5>Introduction</h5>
            </li>
            <ul>
              <li>
                <a href="https://myislam.net/static/Survive.pdf" target="blank">
                  Slides to read and download in PDF Format
                </a>
              </li>
              <li>
                <a href="https://youtu.be/BbRmfxtHUyM" target="blank">
                  YouTube Video [please share]
                </a>
              </li>
              <li>
                <a href="https://myislam.net/static/survive.mp4" target="blank">
                  Video in .mp4 format to downlad or watch on browser
                </a>
              </li>
            </ul>
          </ol>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="heart"
      >
        <ul className={Classes.FocusLinked}>
          <li>
            <h4>Protect Your Heart </h4>
          </li>
          <ol className={Classes.FocusLinked}>
            <ul>
              <li>
                <a href="https://youtu.be/un0_9nQXdnU" target="blank">
                  YouTube Video [please share]
                </a>
              </li>
            </ul>
          </ol>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="trust"
      >
        <ul className={Classes.FocusLinked}>
          <li>
            <h3>The State of Trust - منزلة الثقة</h3>
          </li>
          <p></p>
          <ol className={Classes.FocusLinked}>
            <ul>
              <li>
                <span className={Classes.blink_infinite}>
                  <img
                    className="img-fluid img-profile mx-auto mb-2,"
                    src={arrow_right}
                    alt=""
                    height="30"
                    width="30"
                  />
                </span>
                {'   '}
                <a href="https://youtu.be/C_XCaiwNO3M" target="blank">
                  [English - YouTube Video] The State of Trust - منزلة
                  الثقة[please share]
                </a>
              </li>
              <p></p>
              <li>
                <a href="https://myislam.net/static/trust.pdf" target="blank">
                  The session Written to read and download in PDF Format
                </a>
              </li>
              <p></p>
              <li>
                <span
                  className={Classes.blink}
                  style={{ fontSize: '20px', fontWeight: 'bolder' }}
                >
                  <a
                    href="https://myislam.net/static/2020Oct2Plan.pdf"
                    target="blank"
                  >
                    | 10/2/2020 : This week's Suggested Activities (How to
                    practice) |
                  </a>
                </span>
              </li>
              <p></p>

              <li>
                <a href="https://myislam.net/static/Trust.pptx" target="blank">
                  Slides with Audio to read and download in PowerPoint Format
                </a>
              </li>
              <p></p>
              <li>
                <a href="https://myislam.net/static/Trust.mp4" target="blank">
                  Video in .mp4 format to downlad or watch on browser
                </a>
              </li>
              <p></p>
              <li>
                <h5>References for Trust:</h5>
              </li>
              <ul>
                <li>
                  <a
                    href="https://myislam.net/static/Summary_Of_Madarej.pdf"
                    target="blank"
                  >
                    [English - PDF] Practicing Madarej Assalekeen - تطبيق كتاب
                    مدارج السالكين
                  </a>
                </li>
                <li>
                  <a
                    href="https://myislam.net/static/Survive.pdf"
                    target="blank"
                  >
                    How to survive in the time of Crisis - Slides to read and
                    download in PDF Format
                  </a>
                </li>
                <p />
                <li>
                  <a
                    href="https://medium.com/@madarejassalekeen/practicing-the-way-of-the-travelers-madarij-assalikeen-the-state-of-trust-%D9%85%D9%86%D8%B2%D9%84%D8%A9-%D8%A7%D9%84%D8%AB%D9%82%D8%A9-5a0692b6d3bd"
                    target="blank"
                  >
                    Practicing The way of the travelers (Madarij AsSalikeen) THE
                    STATE OF TRUST — منزلة الثقة
                  </a>
                </li>
                <p />
                <li>
                  <a href="https://youtu.be/BbRmfxtHUyM" target="blank">
                    YouTube Video - How to survive in the time of Crisis [please
                    share]
                  </a>
                </li>
                <li>
                  <a
                    href="https://myislam.net/static/survive.mp4"
                    target="blank"
                  >
                    How to survive in the time of Crisis- Video in .mp4 format
                    to downlad or watch on browser
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/GKwmbdPgl8c" target="blank">
                    [English] Nouman Ali Khan YouTube Video
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/kfhdMWMEVcI" target="blank">
                    [English] Nouman Ali Khan YouTube Video
                  </a>
                </li>
                <p></p>
                <li>
                  <a href="https://youtu.be/Nl6apdB3Br4" target="blank">
                    [English - YouTube] Lessons from the Story of Musa Nouman
                    Ali Khan
                  </a>
                </li>
                <p></p>
                <li>
                  <a href="https://youtu.be/UEPrxfxtKn0" target="blank">
                    [Arabic] منزلة الثقة الشيخ الدكتور فريد الانصاري YouTube
                    Video
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/93F03XWyW-c" target="blank">
                    [Arabic] منزلة الثقة بالله تعالى (1) - د.محمد خير الشعال
                    YouTube Video
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/3kvlKTHlWjg" target="blank">
                    [Arabic] منزلة الثقة بالله تعالى (2) - د.محمد خير الشعال
                    YouTube Video
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/vkHXV6g7u00" target="blank">
                    [Arabic] منزلة الثقة بالله تعالى (3) - د.محمد خير الشعال
                    YouTube Video
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/sbNJZ64QZNg" target="blank">
                    [Arabic] منزلة الثقة بالله تعالى (4) - د.محمد خير الشعال
                    YouTube Video
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/U3pH034c8-I" target="blank">
                    [Arabic] مدارج السالكين منزلة الثقة بالله لفضيلة الشيخ محمد
                    سيد حاج رحمه الله YouTube Video
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/vIg2Sj3Jris" target="blank">
                    [Arabic] مع عمرو خالد - الثقة في الله YouTube Video
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/EvjYWBQ_RVQ" target="blank">
                    [Arabic] منزلة الثقة بالله (21-8-2008) . د/ ياسر برهامى
                    YouTube Video
                  </a>
                </li>
                <li>
                  <a
                    href="https://myislam.net/static/hearts_amr_yaqeen.mp3"
                    target="blank"
                  >
                    [Arabic Only - mp3] اصلاح القلوب مع عمرو خالد - الثقة في
                    اللهTrust Allah (mp3) - Amr Khaled
                  </a>
                </li>
                <li>
                  <a href="hhttps://youtu.be/KgslGsPAuRY" target="blank">
                    [Arabic] خلاصة مدارج السالكين [ 26 ] منازل : التفويض، والثقة
                    بالله تعالى، والتسليم YouTube Video
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/sbNJZ64QZNg" target="blank">
                    [Arabic] منزلة الثقة بالله تعالى - د. محمد راتب النابلسي
                    YouTube Video
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/playlist?list=PLEB7B19DAC6DC2A16"
                    target="blank"
                  >
                    [Arabic Only - YouTube] - رحلة للسعادة - الحلقة 9 - لا للقلق
                    ( حسن الظن بالله )
                  </a>
                </li>
              </ul>
            </ul>
          </ol>
        </ul>
      </section>

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="patience"
      >
        <ul className={Classes.FocusLinked}>
          <li>
            <h3>The State of patience - منزلة الصبر </h3>
          </li>
          <ol className={Classes.FocusLinked}>
            <ul>
              <li>
                <a href="https://youtu.be/1nS1x7U1Fcc" target="blank">
                  YouTube Video [please share]
                </a>
              </li>
              <p />
              <li>
                <a
                  href="https://myislam.net/static/patience.m4a"
                  target="blank"
                >
                  Audio file
                </a>
              </li>
              <p />
              <li>
                <a
                  href="https://myislam.net/static/patience.pdf"
                  target="blank"
                >
                  The session Written to read and download in PDF Format
                </a>
              </li>
              <p></p>
              <li>
                <span className={Classes.blink_infinite}>
                  <img
                    className="img-fluid img-profile mx-auto mb-2,"
                    src={arrow_right}
                    alt=""
                    height="30"
                    width="30"
                  />
                </span>
                {'   '}
                <span
                  className={Classes.blink}
                  style={{ fontSize: '20px', fontWeight: 'bolder' }}
                >
                  <a
                    href="https://myislam.net/static/PracticePatience.pdf"
                    target="blank"
                  >
                    | 10/9/2020 : This week's Suggested Activities (How to
                    become Patient) |
                  </a>
                </span>
              </li>
              <p></p>
              <li>
                <h5>References for patience:</h5>
              </li>
              <ul>
                <li>
                  <a
                    href="https://myislam.net/static/Summary_Of_Madarej.pdf"
                    target="blank"
                  >
                    [English - PDF] Practicing Madarej Assalekeen - تطبيق كتاب
                    مدارج السالكين
                  </a>
                </li>
                <p></p>
                <li>
                  <a
                    href="https://www.whyislam.org/on-faith/moses-and-khidr/"
                    target="blank"
                  >
                    The Story of Moses and Khidr
                  </a>
                </li>

                <p></p>
                <li>
                  <a
                    href="https://www.youtube.com/playlist?list=PLpWfP8Iai5QQXIcJR5S6Tg725hV4PTssC"
                    target="blank"
                  >
                    [English - YouTube] Story of Musa and Khidr - Surah Al Kahf
                    in-depth w/ Nouman Ali Khan
                  </a>
                </li>
                <p></p>
                <p></p>
                <li>
                  <a href="https://youtu.be/MPan2hCwLa8" target="blank">
                    [English - YouTube] Lessons from the Story of Musa and Khidr
                    (a.s.) - Yasir Qadhi
                  </a>
                </li>
                <p></p>
                <li>
                  <a href="https://youtu.be/b3m0ucLx6SA" target="blank">
                    [Arabic - YouTube] عمرو خالد - رحلة للسعادة - الحلقة 17
                    الصبر
                  </a>
                </li>
                <p></p>
                <li>
                  <a href="https://youtu.be/-zP-NXi6HBg" target="blank">
                    [Arabic - YouTube] الصبر على البلاء | الشيخ محمد حسين يعقوب
                  </a>
                </li>
                <p></p>
                <li>
                  <a href="https://youtu.be/8-FltlGvtNI" target="blank">
                    [Arabic - YouTube] من برنامج مدارج السالكين : الصبر على
                    البلاء | الشيخ محمد حسين يعقوب
                  </a>
                </li>
              </ul>
            </ul>
          </ol>
        </ul>
      </section>

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="gratitude"
      >
        <ul className={Classes.FocusLinked}>
          <li>
            <h3>The State of Gratitude - منزلة الشكر </h3>
          </li>
          <ol className={Classes.FocusLinked}>
            <ul>
              <li>
                <a href="https://youtu.be/Y1m8V-CeQ_c" target="blank">
                  YouTube Video [please share]
                </a>
              </li>
              <p />

              <p />
              <li>
                <a
                  href="https://myislam.net/static/PowerOfGratitude.pdf"
                  target="blank"
                >
                  The session Written to read and download in PDF Format
                </a>
              </li>
              <p></p>

              <p />
              <li>
                <a
                  href="https://myislamnet.medium.com/the-power-of-gratitude-in-reaching-happiness-and-treating-stress-anxiety-and-depression-d5c8a38a3e05"
                  target="blank"
                >
                  Medium Article
                </a>
              </li>
              <p></p>

              <li>
                <span className={Classes.blink_infinite}>
                  <img
                    className="img-fluid img-profile mx-auto mb-2,"
                    src={arrow_right}
                    alt=""
                    height="30"
                    width="30"
                  />
                </span>
                {'   '}
                <span
                  className={Classes.blink}
                  style={{ fontSize: '20px', fontWeight: 'bolder' }}
                >
                  <a
                    href="https://myislam.net/static/PowerOfGratitude.pdf"
                    target="blank"
                  >
                    | 11/26/2021 : This week's Suggested Activities (How to
                    become Grateful) |
                  </a>
                </span>
              </li>
              <p></p>
              <li>
                <h5>References for Gratitude:</h5>
              </li>
              <ul>
                <li>
                  <a
                    href="https://islamweb.net/ar/library/index.php?page=bookcontents&idfrom=314&idto=320&bk_no=119&ID=316
"
                    target="blank"
                  >
                    The State of Gratitude
                  </a>
                </li>

                <p></p>
                <li>
                  <a
                    href="https://www.simonandschuster.com/books/The-Power-of-Gratitude/Lois-Blyth/9781782494393"
                    target="blank"
                  >
                    The Power of Gratitude The thankful way to a happier,
                    healthier you By Lois Blyth
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/watch?v=g5-1AGEn8Ew"
                    target="blank"
                  >
                    [English - YouTube] Stations of Gratitude (levels of
                    gratitude) by Hamza Yusuf
                  </a>
                </li>
                <p></p>
                <p></p>
                <li>
                  <a
                    href="https://www.youtube.com/watch?v=3ThUrVXz9j0"
                    target="blank"
                  >
                    [English - YouTube] Your brain is wired for negative
                    thoughts. Here’s how to change it
                  </a>
                </li>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <li>
                  <a
                    href="https://www.youtube.com/watch?v=faXsCFWzse0&t=302s"
                    target="blank"
                  >
                    [English - YouTube] Are We Wired to Think Negatively?
                  </a>
                </li>
                <p></p>
                <p></p>
              </ul>
            </ul>
          </ol>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="grief"
      >
        <ul className={Classes.FocusLinked}>
          <li>
            <h3> The State of Grief - منزلة الحزن </h3>
          </li>
          <ol className={Classes.FocusLinked}>
            <ul>
              <li>
                <a href="https://youtu.be/9v66GblzYVw" target="blank">
                  YouTube Video [please share]
                </a>
              </li>
              <p />

              <p />

              <p></p>

              <p />
              <li>
                <a
                  href="https://myislamnet.medium.com/grief-and-how-to-overcome-it-90389e43505f?source=friends_link&sk=a45a10ff3759af0700cea3ecc00eca61"
                  target="blank"
                >
                  Medium Article
                </a>
              </li>
              <p></p>
            </ul>
          </ol>
        </ul>
      </section>

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="heart"
      >
        <ul className={Classes.FocusLinked}>
          <li>
            <h4>Protect Your Heart </h4>
          </li>
          <ol className={Classes.FocusLinked}>
            <ul>
              <li>
                <a href="https://youtu.be/un0_9nQXdnU" target="blank">
                  YouTube Video [please share]
                </a>
              </li>
            </ul>
          </ol>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="references"
      >
        <h4>References:</h4>
        <ul>
          <li>
            <span>
              [English] Translation of the Book Ranks of the divine seekers
            </span>
            <a
              href="https://myislam.net/static/english_madarij_1.pdf"
              target="blank"
            >
              {' '}
              Part 1
            </a>
            <a
              href="https://myislam.net/static/english_madarij_2.pdf"
              target="blank"
            >
              {' '}
              , Part 2
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/playlist?list=PLNT8BTFvQYHLmHSeXABFEYS-hZ9IwGdWI"
              target="blank"
            >
              [English - YouTube PlayList] Practicing Madarej Assalekeen - تطبيق
              كتاب مدارج السالكين -
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/Summary_Of_Madarej.pdf"
              target="blank"
            >
              [English - PDF] Practicing Madarej Assalekeen - تطبيق كتاب مدارج
              السالكين
            </a>
          </li>
          <li>
            <a href="https://www.amazon.com/dp/B08KP6QQV3" target="blank">
              [English - Amazon Kindle Book] Practicing Madarej Assalekeen -
              تطبيق كتاب مدارج السالكين
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/Madarij-as-Salikeen-Imam-Ibn-Qayim-Al-Jawziyah-ENG.pdf"
              target="blank"
            >
              [English] Book of Madarij Assalekeen - كتاب مدارج السالكين
            </a>
          </li>
          <li>
            <Link to="/publications">All Publications</Link>
          </li>
          <li>
            <Link to="/downloads/">All Downloads</Link>
          </li>
          <li>
            <a
              href="https://ar.wikisource.org/wiki/%D9%85%D8%AF%D8%A7%D8%B1%D8%AC_%D8%A7%D9%84%D8%B3%D8%A7%D9%84%D9%83%D9%8A%D9%86"
              target="blank"
            >
              [Arabic] Book of Madarij Assalekeen - كتاب مدارج السالكين ابن قيم
              الجوزية
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/Madarej_Book_Arabic.pdf"
              target="blank"
            >
              [Arabic - pdf] Book of Madarij Assalekeen - كتاب مدارج السالكين
              ابن قيم الجوزية
            </a>
          </li>
          <li>
            <a href="https://youtu.be/8LwXP7HrZlg">
              Are you searching for Happiness?
            </a>
          </li>
          <li>
            <a href="/duaa">Duaa and Supplication to Allah الدعاء</a>
          </li>
          <li>
            <a href="/hearts" target="blank">
              [English] Purifying our Hearts اصلاح القلوب
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/playlist?list=PLEB7B19DAC6DC2A16"
              target="blank"
            >
              [Arabic Only - YouTube] Amr Khaled Series "A Journey to happiness"
              - رحلة الي السعادة د.عمرو خالد
            </a>
          </li>
          <li>
            <a
              href="https://www.amazon.com/Ibn-Qayyim-al-Jawziyya-Invocation-God/dp/B00NPO7BSC"
              target="blank"
            >
              [English] Book of Al-Wabil al-Sayyib [Ibn al Qaiem] - الوابل الصيب
              من الكلم الطيب
            </a>
          </li>
          <li>
            <a
              href="https://ar.wikisource.org/wiki/%D9%83%D8%AA%D8%A7%D8%A8_%D8%A7%D9%84%D9%88%D8%A7%D8%A8%D9%84_%D8%A7%D9%84%D8%B5%D9%8A%D8%A8"
              target="blank"
            >
              [Arabic] الوابل الصيب من الكلم الطيب ابن قيم الجوزية
            </a>
          </li>
          <li>
            <a
              href="https://www.amazon.com/%E2%80%AB%D9%85%D8%AF%D8%A7%D8%B1%D8%AC-%D8%A7%D9%84%D8%B3%D8%A7%D9%84%D9%83%D9%8A%D9%86-%D9%85%D9%86%D8%A7%D8%B2%D9%84-%D9%86%D8%B3%D8%AA%D8%B9%D9%8A%D9%86-Arabic-ebook/dp/B07771R9B5"
              target="blank"
            >
              [Arabic] كتاب مدارج السالكين ابن قيم الجوزية
            </a>
          </li>
          <li>
            <a
              href="https://myislam.net/static/SicknessAndCure.pdf"
              target="blank"
            >
              [Arabic] الداء والدواء - ابن قيم الجوزية
            </a>
          </li>
          <li>
            <a href="https://yaqob.com/page.php?q=list&id=6" target="blank">
              [Arabic Only - Audio] Madarej Assalekeen - شرح كتاب مدارج السالكين
              - محمد حسين يعقوب - طريق الإسلام
            </a>
          </li>

          <li>
            <a href="https://sunnah.com/" target="blank">
              https://sunnah.com/
            </a>
          </li>
          <li>
            <a href="https://www.dorar.net/hadith" target="blank">
              https://www.dorar.net/hadith
            </a>
          </li>
          <li>
            <a href="http://bit.ly/manazil_alroh2021" target="blank">
              برنامج منازل الروح تقديم الدكتور عمرو خالد في رمضان 2021
            </a>
          </li>
          <li>
            <a
              href="https://www.islamicity.org/quransearch/?AspxAutoDetectCookieSupport=1"
              target="blank"
            >
              Quran SmartSearch
            </a>
          </li>
          <li>
            <a
              href="https://youtube.com/playlist?list=PL9dtl0m55LPp2qLiKI3O7LwPiFDCayqnv"
              target="blank"
            >
              سلسلة إصلاح القلوب - عمرو خالد{' '}
            </a>
          </li>
        </ul>
      </section>
    </div>
  </Layout>
);

export default Current;
